// import {
//   dehydrate,
//   DehydratedState,
//   getCookiesString,
//   getNewCart,
//   QueryClient
// } from '@smu-chile/pkg-unimarc-hooks'
import { Body } from 'components'
// import { GetServerSideProps } from 'next'

const Index = (): React.ReactElement => {
  return <Body />
}

export default Index

// export const getServerSideProps: GetServerSideProps<{
//   dehydratedState?: DehydratedState
// }> = async (context) => {
//   const queryClient = new QueryClient()

//   const { cookies, headers: reqHeaders } = context.req
//   const headers = { cookie: reqHeaders.cookie || getCookiesString(cookies) }

//   if (!cookies?.co_sc) {
//     return { props: {} }
//   }

//   await queryClient.prefetchQuery('/cart-servidor', () => {
//     return getNewCart({ headers })
//   })

//   return {
//     props: {
//       dehydratedState: dehydrate(queryClient)
//     }
//   }
// }
